import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { createI18n } from 'vue-i18n-bridge';
import { camelizeKeys } from 'humps';
import ActionCableVue from 'actioncable-vue';
import InlineSvg from 'vue-inline-svg';
import '@/css/buefy.scss';

import '@/css/application.css';
import store from '@/src/store/index';
import Locales from '@/locales/locales.js';
import Btn from '@/src/components/btn.vue';
import FaqHeader from '@/src/components/landing/faq-header.vue';
import LandingHero from '@/src/components/landing/landing-hero.vue';
import LandingFooter from '@/src/components/landing/landing-footer.vue';
import LandingInfo from '@/src/components/landing/landing-info.vue';
import LandingInfoCard from '@/src/components/landing/landing-info-card.vue';
import LandingInvite from '@/src/components/landing/landing-invite.vue';
import LandingNavbar from '@/src/components/landing/landing-navbar.vue';
import LandingTab from '@/src/components/landing/landing-tab.vue';
import MarkdownSectionCard from '@/src/components/markdown-section-card.vue';

Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: 'error',
  connectionUrl: import.meta.env.VITE_RAILS_ENV === 'production' ?
    (`wss://${import.meta.env.VITE_APPLICATION_HOST}/cable`) : 'ws://localhost:3000/cable',
  connectImmediately: true,
});
Vue.use(VueI18n, { bridge: true });
const i18n = createI18n({
  locale: 'es',
  messages: Locales.messages,
}, VueI18n);
Vue.use(i18n);

Vue.component('inline-svg', InlineSvg);

Vue.filter('camelizeKeys', camelizeKeys);

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    el: '#vue-app',
    components: {
      Btn,
      FaqHeader,
      LandingFooter,
      LandingHero,
      LandingInfo,
      LandingInfoCard,
      LandingInvite,
      LandingNavbar,
      LandingTab,
      MarkdownSectionCard,
    },
    store,
    i18n,
  });

  return app;
});
